<!--
 * @Description: 品质管理-检查标准库
 * @Author: 马俊
 * @Date: 2021-04-27 11:16:21
 * @LastEditors: 马俊
 * @LastEditTime:
 -->
<template>
  <div class="assetRegistrationList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams="searchParams"
      :isMultiSelect="true"
      :headers="headers"
      :selectable="selectable"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
        <v-button text="导入" permission="import" @click="importHandle"></v-button>
      </template>

      <template #tableLeftSlot>
        <div class="table-left" v-show="treeShow">
          <!-- 搜索框 -->
          <div class="tree-search">
            <v-input 
              class="search-input"
              :width="160"
              v-model="filterText"
              prefix-icon="el-icon-search"
              placeholder="请输入名称"
            />
            <v-button text="新增" class="add-button" permission="addPzBtn" @click="createCategory"/>
          </div>
          <!-- 控件树 -->
          <div class="tree-group">
            <button-tree
              ref="buttonTree"
              v-loading="treeLoading"
              :key="keyTree"
              :data="treeData"
              :defaultProps="treeProps"
              :btnOps="btnOps"
              :filterText="filterText"
              :filterNodeMethod="filterNodeMethod"
              :defaultExpandedKeys="defaultExpandedKeys"
              :defaultCheckedKeys="defaultCheckedKeys"
              :currentNodeKey="currentNodeKey"
              :highlightCurrent="true"
              :expand-on-click-node="false"
              :showCheckbox="true"
              :isShowBth="isShowBth"
              :checkStrictly="true"
              @buttonClick="buttonNode"
              @nodeClick="nodeClick"
              @check="checkChange"
            />
          </div>
        </div>
        <div class="treeSwith" @click="treeSwith">
          <img v-if="treeShow" :src="treeOffImgSrc" />
          <img v-if="!treeShow" :src="treeOnImgSrc" />
        </div>
      </template>

      <template #searchSlot>
        <v-input label="检查项名称" placeholder="检查项名称" v-model="searchParams.name" />
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"/>
        <v-datepicker
          type="rangedatetimer"
          label="创建时间"
          format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.createStartTime"
          :endTime.sync="searchParams.createEndTime"
        />
      </template>
      <template #operateSlot="scope">
        <v-button v-if="isShowEditBtn(scope.row)" text="编辑" type="text" permission="edit" @click="edit(scope.row)" />
      </template>
      <template #batchSlot="scope">
        <v-button text="批量停用" permission="batchStop" :disabled="scope.selectedData.ids.length === 0"  @click="batch(scope.selectedData)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { ButtonTree } from 'components/bussiness/index'
import {
  getQualityCheckItemListURL,
  getQualityCheckCategoryTreeURL,
  getItemNumberByCategoryIdURL,
  deleteQualityCheckCategoryByIdURL,
  batchStopUsingQualityCheckItemURL
} from './api'
import {
  statusMap,
  statusOps,
  btnOps
} from './map'
import treeOnImgSrc from './img/treeOn.png'
import treeOffImgSrc from './img/treeOff.png'

export default {
  name: 'qualityStandardLibraryList',
  components: {
    ButtonTree,
  },
  data () {
    return {
      treeOnImgSrc,
      treeOffImgSrc,
      searchUrl: getQualityCheckItemListURL,
      searchParams: {
        name: '',
        createStartTime: '',
        createEndTime: '',
        status: undefined,
        categoryIdList: undefined
      },
      headers: [
        {
          prop: 'categoryName',
          label: '所属类目'
        },
        {
          prop: 'name',
          label: '检查项名称'
        },
        {
          prop: 'criterion',
          label: '检查项标准'
        },
        {
          prop: 'description',
          label: '检查方法及判定细则'
        },
        {
          prop: 'status',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ],
      statusOps: statusOps(1),
      statusMap,
      filterText: '',
      currentNodeKey: '',
      defaultExpandedKeys: [],
      defaultCheckedKeys: [],
      treeLoading: false,
      btnOps,
      treeData: [],
      treeProps: {
        id: 'id', // 必须
        children: 'subTree', // 必须
        label: 'categoryName', // 必须
        btn: 'operateArr' // 必须
      },
      treeShow: true,
      keyTree: 0,
      selectTreeData: '',
    }
  },
  computed: {
    userType () {
      return this.$store.state.userInfo.userType
    }
  },
  created () {
    this.getTreeList()
    let defaultCheckedKeys = JSON.parse(sessionStorage.getItem('defaultCheckedKeys') || '[]') || []
    this.defaultCheckedKeys = defaultCheckedKeys;
    this.$set(this.searchParams, 'categoryIdList', defaultCheckedKeys.join(','))
  },
  methods: {
    create () {
      if(!this.selectTreeData.id) {
        this.$message('请先在左侧选择类目')
      } else {
        let categoryName = this.getSelectTreeName(this.selectTreeData,this.treeData)
        this.$router.push({
          name: 'qualityStandardLibraryForm',
          query: {
            categoryName,
            categoryId: this.defaultExpandedKeys[0]
          }
        })
      }
    },
    // 根据选中节点获取各层级完整的名称
    getSelectTreeName(selectTreeData,treeData) {
      // 把树拉平
      let flattenTreeDataClosure = (data) => {
        const treeData = JSON.parse(JSON.stringify(data));
        const flattenData = [];
        function flattenTree(data, parentKey) {
          data.forEach((ele) => {
            const { subTree, id, ...other } = ele;
            flattenData.push({ ...other, id, parentKey });
            if (subTree) {
              flattenTree(subTree, id);
            }
          });
        }
        flattenTree(treeData, null);
        return flattenData;
      };
      let result = flattenTreeDataClosure(treeData);
      //  找到所有的父
      let findParent = (id, flattenTree) => {
        const parentArr = []; // 存储所有的父级元素
        function find(id, flattenTree) {
          flattenTree.forEach((ele) => {
            if (ele.id === id) {
              parentArr.unshift(ele.categoryName);
              find(ele.parentKey, flattenTree);
            }
          });
        }
        find(id, flattenTree);
        return parentArr;
      };
      return  findParent(selectTreeData.id, result).join('-')
    },
    edit (row) {
      this.$router.push({
        name: 'qualityStandardLibraryForm',
        query: {
          id: row.id, 
          categoryName: row.categoryName,
          categoryId: row.categoryId
        }
      })
    },
    isShowEditBtn (row) {
      if (this.userType == '106') {
        return true
      }
      if (this.userType == '102' && row.hierarchy == '1') {
        return true
      }
      if (this.userType == '100') {
        return true
      }
      return false
    },
    getTreeList () { // 获取树节点数据
      this.treeLoading = true
      this.$axios.get(getQualityCheckCategoryTreeURL)
        .then((res) => {
          if (res.status === 100 && res.msg === 'ok') {
            // TODO 后端返回树组织每一层级的按钮列表
            this.treeData = res.data
            this.keyTree += 1
          }
        }).finally(() => {
          this.treeLoading = false
        })
    },
    treeSwith () { // 树的显示隐藏
      this.treeShow = !this.treeShow
    },
    filterNodeMethod (value, data, node) { // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) {
        if (node.expanded) {
          node.expanded = false
        }
        return true
      }
      if( data[this.treeProps.label].indexOf(value) !== -1){
        return true
      }else{
        
        if(node.parent && !Array.isArray(node.parent.data) && node.parent.visible){
          
          return true
        }else{
          return false
        }
      }
    },
    isShowBth (data, item) {
      if (data.categoryLevel == 5 && item.value == 'addPzBtn') {
        return false
      }
      return true
    },
    createCategory (query) { // 新增
      this.$router.push({
        name: 'qualityCategoryForm',
        query
      })
    },
    editCategory (query) {
      this.$router.push({
        name: 'qualityCategoryForm',
        query
      })
    },
    deleteCategory (data) {
      const { categoryLevel, id } = data;
      let message = `是否删除此条数据,请确认操作！`
      this.$confirm(message).then(async (result) => {
        if (!result) {
          return
        }
        // 1. 先查询类目关联的资产数量
        let res = null
        this.loading = true
        res = await this.$axios.get(getItemNumberByCategoryIdURL, {
          params: {
            id: id,
            categoryLevel: categoryLevel
          }
        })
        if (res && res.data !== 0) {
          this.loading = false
          let msg = '无法删除此类目，请先取消关联检查标准库！'
          this.$confirm(msg)
          return
        } else if (!res || res.data === null) {
          return
        }
        // 2. 删除类目
        this.$axios.post(deleteQualityCheckCategoryByIdURL, {
          id: id,
          categoryLevel: categoryLevel
        }).then((res) => {
          if (res.status === 100) {
            // 更新成功后
            this.getTreeList()
            this.$message.success('操作成功')
          }
        }).finally(() => {
          this.loading = false
        })
      })
    },
    buttonNode (data, item) { // 点击树节点的弹框按钮
      // data 为节点对象的数据, item 为按钮的对象数据
      if (item.value === 'addPzBtn') { // 当点击编辑按钮时
        this.createCategory({ parentId: data.id })
      }
      if (item.value === 'editPzBtn') {
        this.editCategory({ id: data.id })
      }
      if (item.value === 'deletePzBtn') {
        this.deleteCategory(data)
      }
    },
    nodeClick (data, node, nodeVue) { // 点击树节点文字
      /* data 节点数据对象, node 节点的vue虚拟对象, nodeVue 节点组件本身 */
      const val = data && (data[this.treeProps.id] || undefined)
      this.defaultExpandedKeys = val ? [val] : []
      this.currentNodeKey = val
      this.selectTreeData = data;
    },
    checkChange (item, data) {
      const { checkedNodes = [] } = data
      // let checkData = checkedNodes.filter(item => +item.categoryLevel === 3 )
      let categoryIdList = checkedNodes.map(item => item.id)
      sessionStorage.setItem('defaultCheckedKeys', JSON.stringify(categoryIdList))
      this.$set(this.searchParams, 'categoryIdList', categoryIdList.join(','))
      this.$refs.list.searchData();
    },
    importHandle () {
      this.$router.push({
        name: 'qualityStandardLibraryImport'
      })
    },
    selectable (row) {
      return !row.status
    },
    async batch (selectedData) {
      console.log(selectedData,'selectedData');
      let res = await this.$confirm('请确认是否执行此操作，停用后此条检查项将无法被使用')
      if (res) {
        this.$axios({
          method: 'post',
          url: batchStopUsingQualityCheckItemURL,
          data:selectedData.ids
        }).then(res => {
          if (res.status == '100') {
            if (res.data) {
              this.$alert(`“${res.data}”  已经关联检查表模板，无法停用！请先解除关联。`,{title:"错误提示"})
            } else {
              this.$message({
                type: 'success',
                message: '操作成功',
                center: true
              })
            }
            this.$refs.list.searchData()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-left {
    height: 100%;
    display: inline-block;
    position: relative;
    .tree-search {
      text-align: center;
      display: flex;
      ::v-deep .v-control {
        // width: 100%;
        flex: 1;
        .el-input {
          // width: 100% !important;
          flex: 1;
          .el-input__inner {
            outline: none;
            padding: 2px 10px 2px 30px;
            height: 33px;
          }
          .el-input__icon {
            line-height: 33px;
          }
          .el-input__suffix {
            line-height: 33px;
          }
        }
      }
      .add-button {
        margin-left: 8px;
        ::v-deep .el-button{
          padding: 0 8px;
        }
      }
    }
    ::v-deep .el-loading-mask {
      z-index: 100!important;
    }
    .tree-select {
      width: 100%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      position: absolute;
      top: 38px;
      left: 0;
      display: flex;
      z-index: 200;
      .select-button {
        width: 100%;
        // height: 100%;
        flex: 1;
        ::v-deep .el-button {
          padding: 0 8px;
          text-align: left;
          color: #414142;
          background-color: #e3e5e9;
          border-color: #e8e9eb;
          i {
            float: right;
          }
        }
      }
      .select {
        ::v-deep .el-button {
          background: linear-gradient(90deg, #43a8ff 0%, #529fff 99%);
          color: #fff;
        }
      }
      .add-button {
        margin-left: 8px;
        ::v-deep .el-button{
          padding: 0 8px;
        }
      }
      .tree-select__list {
        position: absolute;
        top: 30px;
        left: 0;
        text-align: left;
        display: inline-block;
        width: 100%;
        border: 1px solid #e4e7ed;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        z-index: 200;
        > li {
          cursor: pointer;
          padding: 0px 12px;
          color: #909399;
          &:hover {
            color: #1B8CF2;
            background-color: #f5f7fa;
          }
        }
      }
    }
    .tree-group {
      margin-top: 40px;
      height: calc(100% - 73px);
      width: 240px;
    }
    .treeSwith {
      cursor: pointer;
      display: inline-block;
      position: relative;
      height: 100%;
      vertical-align: top;
      width: 36px;
      img {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 36px;
        height: 80px;
      }
      &-bar {
        width: 8px;
        height: 160px;
        background: linear-gradient(
          to top left,
          rgba(228, 228, 228, 1) 0%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }
.dialog-wrapper {
  max-height: 30vh;
  overflow: auto;
  text-align: left;
  padding-left: 1px;
  .sign-item {
    padding-bottom: 5px!important;
  }
}
</style>
